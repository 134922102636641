/*global jQuery */
/*!
 * Lettering.JS 0.7.0
 *
 * Copyright 2010, Dave Rupert http://daverupert.com
 * Released under the WTFPL license
 * http://sam.zoy.org/wtfpl/
 *
 * Thanks to Paul Irish - http://paulirish.com - for the feedback.
 *
 * Date: Mon Sep 20 17:14:00 2010 -0600
 */
(function ($) {
  function injector(t, splitter, klass, after) {
    var text = t.text(),
      a = text.split(splitter),
      inject = "";
    if (a.length) {
      $(a).each(function (i, item) {
        inject +=
          '<span class="' +
          klass +
          (i + 1) +
          '" aria-hidden="true">' +
          item +
          "</span>" +
          after;
      });
      t.attr("aria-label", text).empty().append(inject);
    }
  }

  var methods = {
    init: function () {
      return this.each(function () {
        injector($(this), "", "char", "");
      });
    },

    words: function () {
      return this.each(function () {
        injector($(this), " ", "word", " ");
      });
    },

    lines: function () {
      return this.each(function () {
        var r = "eefec303079ad17405c889e092e105b0";
        // Because it's hard to split a <br/> tag consistently across browsers,
        // (*ahem* IE *ahem*), we replace all <br/> instances with an md5 hash
        // (of the word "split").  If you're trying to use this plugin on that
        // md5 hash string, it will fail because you're being ridiculous.
        injector($(this).children("br").replaceWith(r).end(), r, "line", "");
      });
    },
  };

  $.fn.lettering = function (method) {
    // Method calling logic
    if (method && methods[method]) {
      return methods[method].apply(this, [].slice.call(arguments, 1));
    } else if (method === "letters" || !method) {
      return methods.init.apply(this, [].slice.call(arguments, 0)); // always pass an array
    }
    $.error("Method " + method + " does not exist on jQuery.lettering");
    return this;
  };
})(jQuery);

// animation

function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
    y = direction * 100;
  if (elem.classList.contains("gs_reveal_fromLeft")) {
    x = -200;
    y = 0;
  } else if (elem.classList.contains("gs_reveal_fromRight")) {
    x = 200;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  elem.classList.add("isActive");

  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto",
    }
  );
}

function typeIt(elem) {
  new TypeIt(elem, {
    speed: 30,
    loop: false,
  }).go();
}

//test
function hide(elem) {
  gsap.set(elem, { autoAlpha: 0 });
  elem.classList.remove("isActive");
}

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger);

  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    //  hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () {
        animateFrom(elem);
      },
      onEnterBack: function () {
        animateFrom(elem, -1);
      },
      onLeave: function () {
        hide(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });

  window.location.href.indexOf("/ru/") != -1
    ? null
    : gsap.utils.toArray(".gs_type").forEach(function (elem) {
        //  hide(elem); // assure that the element is hidden when scrolled into view

        ScrollTrigger.create({
          trigger: elem,
          onEnter: function () {
            typeIt(elem);
          },
        });
      });
});

// gs_reveal ipsType_center
// gs_reveal gs_reveal_fromLeft
// gs_reveal gs_reveal_fromRight

//
let allPasswords = document.querySelectorAll("input[type=password]");

let eays;
allPasswords.forEach((element) => {
  let parent = element.parentNode;
  let wrapper = document.createElement("div");
  wrapper.classList.add("input-password-parent");

  parent.replaceChild(wrapper, element);
  wrapper.appendChild(element);

  element.parentNode.style.position = "relative";
  eays = document.createElement("button");
  eays.setAttribute("type", "button");
  eays.classList.add("show-hide");
  eays.innerHTML = `<svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9.36786C3.32558 -2.74001 20.5349 -0.634295 21 8.84143" stroke="#000"/>
                      <circle cx="11.5" cy="7.5" r="2.5" fill="#000"/>
                    </svg>
                <div class="password-line off">
                  <svg width="26" height="14" viewBox="0 0 26 14" fill="#212942" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.93469 13C3.36023 12.019 4.79217 11.0915 6.35934 10.3407C9.49128 8.84034 12.232 7.65174 15.2979 6.02005C16.6476 5.30173 18.4132 4.34126 19.7125 3.53937C20.6154 2.98217 21.5272 2.49591 22.5232 2.11885C22.9563 1.95487 23.3801 1.77018 23.8123 1.60466C23.885 1.57678 23.9788 1.50201 25.0001 1" stroke="#212942" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>`;
  element.parentNode.append(eays);
});

// show-hide password
document.querySelectorAll("input[type=password]")?.forEach((el) => {
  el.parentNode.querySelector(".show-hide")?.addEventListener("click", () => {
    if (el.getAttribute("type") === "password") {
      el.setAttribute("type", "text");
    } else {
      el.setAttribute("type", "password");
    }

    el.parentNode.querySelector(".password-line")?.classList.toggle("show");
    el.parentNode.querySelector(".password-line")?.classList.toggle("off");
  });
});
// end show-hide password

// hide <a href="/password-reset/" title="Password Lost and Found." hidden="">Lost your password?</a> on login page
// document
//   .querySelectorAll('[title="Password Lost and Found."]')
//   .forEach((el) => {
//     el.setAttribute("hidden", "");
//   });

// Track the mouse position
let mouse = { x: 0, y: 0 };

window.addEventListener("mousemove", (event) => {
  mouse = getMousePosition(event);
});

// Cursor Implementation
class Cursor {
  constructor(element, doLerp = false) {
    this.DOM = { element, doLerp };
    this.DOM.element.style.opacity = 0;

    this.bounds = this.DOM.element.getBoundingClientRect();

    this.renderedStyles = {
      tx: { previous: 0, current: 0, amt: doLerp ? 0.2 : 1 },
      ty: { previous: 0, current: 0, amt: doLerp ? 0.2 : 1 },
      scale: { previous: 1, current: 1, amt: doLerp ? 0.17 : 1 },
      opacity: { previous: 1, current: 1, amt: doLerp ? 0.17 : 1 },
    };

    this.onMouseMoveEvent = () => {
      this.renderedStyles.tx.previous = this.renderedStyles.tx.current =
        mouse.x - this.bounds.width / 2;
      this.renderedStyles.ty.previous = this.renderedStyles.ty.current =
        mouse.y - this.bounds.height / 2;

      gsap.to(this.DOM.element, {
        duration: 0.9,
        ease: "Power3.easeOut",
        opacity: 1,
      });

      requestAnimationFrame(() => this.render());

      window.removeEventListener("mousemove", this.onMouseMoveEvent);
    };

    window.addEventListener("mousemove", this.onMouseMoveEvent);
  }

  enter() {
    this.renderedStyles.scale.current = 1.8;
  }

  leave() {
    this.renderedStyles.scale.current = 1;
  }

  render() {
    this.renderedStyles.tx.current = mouse.x - this.bounds.width / 2;
    this.renderedStyles.ty.current = mouse.y - this.bounds.height / 2;

    for (const key in this.renderedStyles) {
      this.renderedStyles[key].previous = lerp(
        this.renderedStyles[key].previous,
        this.renderedStyles[key].current,
        this.renderedStyles[key].amt
      );
    }

    this.DOM.element.style.transform = `translateX(${this.renderedStyles.tx.previous}px) translateY(${this.renderedStyles.ty.previous}px) scale(${this.renderedStyles.scale.previous})`;
    this.DOM.element.style.opacity = this.renderedStyles.opacity.previous;

    requestAnimationFrame(() => this.render());
  }
}

// Initialize cursor
const cursorSphere = new Cursor(document.querySelector(".cursor-sphere"), true);
new Cursor(document.querySelector(".cursor"));

// Mouse cursor hovers
const hoverText = document.querySelector("h1");
// hoverText.addEventListener("mouseenter", () => cursorSphere.enter());
// hoverText.addEventListener("mouseleave", () => cursorSphere.leave());

// Linear interpolation
function lerp(a, b, n) {
  return (1 - n) * a + n * b;
}

// Gets the mouse position
function getMousePosition(event) {
  let positionX = 0;
  let positionY = 0;

  if (!event) event = window.event;

  if (event.pageX && event.pageY) {
    positionX = event.clientX;
    positionY = event.clientY;
  } else if (event.clientX && event.clientY) {
    positionX =
      event.clientX +
      document.body.scrollLeft +
      document.documentElement.scrollLeft;
    positionY =
      event.clientY +
      document.body.scrollTop +
      document.documentElement.scrollTop;
  }

  return { x: positionX, y: positionY };
}

$("input, textarea").addClass("effect-12");
$("select").each(function () {
  var $this = $(this),
    numberOfOptions = $(this).children("option").length;

  $this.addClass("select-hidden");
  $this.wrap('<div class="select"></div>');
  $this.after('<div class="select-styled"></div>');

  var $styledSelect = $this.next("div.select-styled");
  $styledSelect.text($this.children("option").eq(0).text());

  var $list = $("<ul />", {
    class: "select-options",
  }).insertAfter($styledSelect);

  for (var i = 0; i < numberOfOptions; i++) {
    $("<li />", {
      text: $this.children("option").eq(i).text(),
      rel: $this.children("option").eq(i).val(),
    }).appendTo($list);
  }

  var $listItems = $list.children("li");

  $styledSelect.click(function (e) {
    e.stopPropagation();
    $("div.select-styled.active")
      .not(this)
      .each(function () {
        $(this).removeClass("active").next("ul.select-options").hide();
      });
    $(this).toggleClass("active").next("ul.select-options").toggle();
  });

  $listItems.click(function (e) {
    e.stopPropagation();
    $styledSelect.text($(this).text()).removeClass("active");
    $this.val($(this).attr("rel"));
    $list.hide();
    console.log($this.val());
  });

  $(document).click(function () {
    $styledSelect.removeClass("active");
    $list.hide();
  });
});

// ------------- account like um -------------------------------_
let dataTitle = "";
document.addEventListener("DOMContentLoaded", function () {
  setTimeout(() => {
    if (
      document.querySelector(
        ".wppb-heading[data-title='Delete Account'], .wppb-heading[data-title='Удалить аккаунт']"
      )
    ) {
      let elDeleteAcc = document.createElement("li");

      elDeleteAcc.classList.add("wppb-form-field");
      elDeleteAcc.classList.add("wppb-delete-account-box");
      elDeleteAcc.classList.add("pbpl-class");

      window.location.href.indexOf("/ru/") != -1
        ? elDeleteAcc.setAttribute("data-title", "Удалить аккаунт")
        : elDeleteAcc.setAttribute("data-title", "Delete Account");

      elDeleteAcc.innerHTML = `
      <p>Are you sure you want to delete your account? This will erase all of your account data from the site. To delete
      your account enter your password below.</p>
      <label>Password</label>
      <input type="password" name="delete-acc-pass" id="delete-acc-pass" placeholder="Password *">
      <label for="delete-acc-pass" class="notranslate">Error password</label>
      <a href="${window.location.origin}/?wppb_user=${user_id}&wppb_action=wppb_delete_user&wppb_nonce=${its_me_mario}" class="button button-white" id="delete-acc" disabled>Delete Account</a>
    `;

      document
        .querySelector(
          ".wppb-heading[data-title='Delete Account'], .wppb-heading[data-title='Удалить аккаунт']"
        )
        .after(elDeleteAcc);

      document
        .querySelector("#delete-acc-pass")
        .addEventListener("keyup", (e) => {
          checkPassQuery(
            document.querySelector("#delete-acc-pass"),
            document.querySelectorAll("#delete-acc")
          );
        });
    }
  }, 0);
  // document.querySelector("main script[type='text/javascript']")
  // ? document.querySelector("main script[type='text/javascript']").remove()
  // : null;

  if (document.querySelectorAll("form.wppb-user-forms ul li")) {
    // задаємо актрибу "data-title" нашим полям
    document.querySelectorAll("form.wppb-user-forms ul li").forEach((el) => {
      el.classList.contains("wppb-heading")
        ? (dataTitle = el.textContent)
        : null;
      dataTitle != "" ? el.setAttribute("data-title", dataTitle) : null;
    });

    // при наитискані на кастомну кнопку змінювати контент
    document.querySelectorAll(".acc-header button").forEach((el) => {
      el.setAttribute("data-title", el.textContent.trim());

      el.addEventListener("click", () => {
        dataTitle = el.getAttribute("data-title");

        if (dataTitle == "Delete Account" || dataTitle == "Удалить аккаунт") {
          document
            .querySelector("#wppb-edit-user-account")
            .classList.add("hideButton");
        } else {
          document
            .querySelector("#wppb-edit-user-account")
            .classList.remove("hideButton");
        }

        document.querySelectorAll(`li[data-title]`).forEach((e) => {
          e.classList.remove("show");
          e.getAttribute("data-title") == dataTitle
            ? e.classList.add("show")
            : null;
        });

        document.querySelectorAll(".acc-header button").forEach((el) => {
          el.classList.remove("current");
        });
        el.classList.add("current");
      });
    });

    document.querySelector(".acc-header button")?.click();
  }
});
// ------------- end account like um -------------------------------_

// delete account

let queryCount = 0;

const checkPassQuery = (inputCheck, responseEl) => {
  document
    .querySelectorAll('[for="delete-acc-pass"]')
    .forEach((el) => el.classList.add("show")); // show wait text

  responseEl.forEach((el) => {
    el.setAttribute("disabled", ""); // disabled delete account button
  });

  queryCount++; // counter

  document
    .querySelectorAll('[for="delete-acc-pass"]')
    .forEach(
      (el) =>
        (el.textContent =
          window.location.href.indexOf("/ru/") != -1
            ? "Подождите, мы проверяем ваш пароль"
            : "Please wait, we are checking your password")
    );

  jQuery.post(
    `${document.location.origin}/wp/wp-admin/admin-ajax.php?action=chekc_old_pass`,
    {
      action: "chekc_old_pass",
      user_id: user_id,
      old_pass: inputCheck.value,
    },
    function (response) {
      queryCount--;

      if (response == true) {
        if (queryCount < 1) {
          document
            .querySelectorAll('[for="delete-acc-pass"]')
            .forEach((el) => el.classList.remove("show"));

          responseEl.forEach((el) => {
            el.removeAttribute("disabled");
          });
        }
      } else {
        if (queryCount < 1) {
          responseEl.forEach((el) => {
            el.setAttribute("disabled", ""); // disabled delete account button
          });

          document
            .querySelectorAll('[for="delete-acc-pass"]')
            .forEach(
              (el) =>
                (el.textContent =
                  window.location.href.indexOf("/ru/") != -1
                    ? (el.textContent = "Неправильный пароль")
                    : (el.textContent = "Error password"))
            );
        }
      }
    }
  );
};

const toggleMenu = () => {
  const openMenu = document.querySelector(".button-open");
  const closeMenu = document.querySelector(".button-close");
  openMenu.addEventListener("click", () => {
    openMenu.classList.toggle("button-back");
    closeMenu.classList.toggle("button-front");
    gsap.to(".hidden_header", 1, {
      y: 0,
    });
  });
  closeMenu.addEventListener("click", () => {
    closeMenu.classList.toggle("button-front");
    openMenu.classList.toggle("button-back");
    gsap.to(".hidden_header", 1, {
      y: "-100%",
    });
  });
};

toggleMenu();

gsap.defaults({ duration: 0.5 });

const items = document.querySelectorAll(".item"); // returns a NodeList

items.forEach(function (item, index) {
  // console.log(item, index)
  const tl = gsap
    .timeline({ paused: true })
    .to(item.querySelector(".text"), {
      color: "#fff",
      x: 10,
      scale: 1.3,
      textShadow: "0 2px 0 	hsl(16, 72%, 59%), 0 4px 0 	hsl(245, 28%, 46%)",
      transformOrigin: "left center",
    })
    .to(
      item.querySelector(".dot"),
      { backgroundColor: "hsl(245, 28%, 46%)", scale: 1.5 },
      0
    );

  item.addEventListener("mouseenter", () => tl.play());
  item.addEventListener("mouseleave", () => tl.reverse());
});

$(".banner_title, .pre_footer_block .inherit_title").lettering();

//blocks_from_right
// gsap.to('.block_form_right', 0, {
//   x: 1400
// })
gsap.fromTo(
  ".blocks_from_right",
  1,
  {
    scrollTrigger: {
      trigger: ".blocks_from_right",
      start: "top 65%",
      end: "top 45%",
      // markers: 'true',
      scrub: 1,
    },
    x: 200,
  },
  {
    scrollTrigger: {
      trigger: ".blocks_from_right",
      start: "top 65%",
      end: "top 25%",
      // markers: 'true',
      scrub: 1,
    },
    x: -300,
  }
);

gsap.fromTo(
  ".block_form_left",
  1,
  {
    x: -400,
  },
  {
    scrollTrigger: {
      trigger: ".block_form_left",
      start: "top 40%",
      end: "top 15%",
      // markers: 'true',
      scrub: 1,
    },
    x: 200,
  }
);

TweenMax.staggerFrom(
  ".animated_svg rect, .animated_svg path, .animated_svg circle",
  1,
  {
    delay: 0.4,
    fill: "transparent",
    height: "0",
    width: "0",
    ease: "back",
  },
  0.3
);

TweenMax.staggerFromTo(
  ".stroke_animate",
  1,
  {
    "stroke-dasharray": function (index, target) {
      let a = (index, target);
      return a.getTotalLength();
    },
    "stroke-dashoffset": function (index, target) {
      let a = (index, target);
      return a.getTotalLength();
    },
  },
  {
    "stroke-dashoffset": "0",
  },
  0.3
);

function logo_animate() {
  TweenMax.staggerFrom(
    ".animated_svg_2 rect, .animated_svg_2 path, .animated_svg_2 circle",
    1,
    {
      delay: 0.4,
      fill: "transparent",
      height: "0",
      width: "0",
      ease: "back",
    },
    0.3
  );

  TweenMax.staggerFromTo(
    ".stroke_animate_2",
    1,
    {
      "stroke-dasharray": function (index, target) {
        let a = (index, target);
        return a.getTotalLength();
      },
      "stroke-dashoffset": function (index, target) {
        let a = (index, target);
        return a.getTotalLength();
      },
    },
    {
      "stroke-dashoffset": "0",
    },
    0.3
  );
}

$("svg").mouseenter(function () {
  logo_animate();
});

function preloaderhide() {
  $(".preloader").hide("300");
}

setTimeout(preloaderhide, 1000);

// var rellax = new Rellax('.rellax');

// $('.for_second_block').css('height', $('.second_block').height());
// console.log($('.second_block').height());
$(document).ready(function () {
  $(".for_second_block").css(
    "margin-top",
    $(".banner .container").height() + 104
  );
  // $('.second_block').css('top', $('.banner .container').height() + 104);
});

// $(window).scroll(function () {
//   $('.banner').scrollTop = $(this).scrollTop();
// })

// $(document).ready(function() {
//   var div = $(".banner");
// div.scrollTop(div.prop('scrollHeight'));
// })

// $('.banner').scroll(function () {

//   if ( $(this).scrollTop() >= $(this).prop('scrollHeight') - $('.banner').height() ) {
//     // alert(1);
//     $(this).css('overflow-y', 'hidden')
//   }
// })

$(document).scroll(function () {
  if ($(this).scrollTop() > 1700) {
    $(".banner").css("visibility", "hidden");
  } else {
    $(".banner").css("visibility", "visible");
  }
});

// var scrollPos = 0;
// $(window).scroll(function(){
//    var st = $(this).scrollTop();
//    if (st > scrollPos){
//      // down
//      console.log('donw')
//    } else {
//      // up
//     $('.banner').css('overflow-y', 'scroll');

//    }
//    scrollPos = st;
// });

// gsap.to('.for_banner', 0, {
//   scrollTrigger: {
//       trigger: '.banner',
//       start: '100% 80%',
//       end: 'bottom 100px',
//       markers: 'true',
//   },
//   'position': 'fixed',
//   'transform': 'translateY(-30%)'
// })

// gsap.to('.for_second_block', 0, {
//   scrollTrigger: {
//       trigger: '.for_second_block',
//       start: '0% 0%',
//       end: 'bottom 100px',
//       markers: 'true',
//   },
//   // 'position': 'fixed',
//   // 'transform': 'translateY(-30%)'
//   opacity: '0.2'
// })

// gsap.to('.banner', 0, {
//   scrollTrigger: {
//       // trigger: 'body',
//       start: '100px',
//       end: '900px',
//       markers: 'true',
//       scrub: true
//   },
//   // 'position': 'fixed',
//   // 'transform': 'translateY(-30%)'
//   'top': '-400px',
//   opacity: 1
// })

gsap.to(".banner", 1, {
  scrollTrigger: {
    // trigger: 'header',
    start: "10px",
    end: "900px",
    // markers: 'true',
    scrub: "1",
  },
  // transform: 'translateY(0px) translateX(0px)  scale(1)',
  // 'font-size': '24px',
  // 'opacity': '0',
  top: "-600px",
  ease: Power2.easeOut,
});

let sliderOffset = $(".key_facts_title").offset();
$(".for_slider_block, .block_form_left")[0]
  ? $(".for_slider_block, .block_form_left").css(
      "margin-left",
      sliderOffset.left
    )
  : null;
$(".slider_container").slick({
  slidesToShow: 2,
});

!(window.location.href.indexOf("/ru/") != -1)
  ? document.querySelector(".homepage_wrapper .like_svg")
    ? new TypeIt(".homepage_wrapper .like_svg", {
        strings: "",
        speed: 5,
        loop: false,
      }).go()
    : null
  : null;

// add quantity product to cart
$(".consultation-buy-btn").click(function () {
  // if (window.location.href.indexOf("/ru/") != -1) {
  //   $(this).attr(
  //     "href",
  //     `/ru/?add-to-cart=58&quantity=${
  //       document.querySelector(".consultation-minutes").value
  //     }`
  //   );
  // } else {
  //   $(this).attr(
  //     "href",
  //     `/?add-to-cart=58&quantity=${
  //       document.querySelector(".consultation-minutes").value
  //     }`
  //   );
  // }
});

$(".consultation-buy-btn")[0]
  ? $(".consultation-buy-btn").click(function () {
      // console.log(this, this.getAttribute("data-quantity"));
      this.setAttribute(
        "data-quantity",
        document.querySelector(".consultation-minutes").value
      );
    })
  : null;

document.querySelectorAll("#quantity-minus-prod").forEach((el) => {
  el.addEventListener("click", (e) => {
    e.preventDefault();
    e.target.closest(".product-quantity").querySelector("#quantity").stepDown();
  });
});

document.querySelectorAll("#quantity-plus-prod").forEach((el) => {
  el.addEventListener("click", (e) => {
    e.preventDefault();
    e.target.closest(".product-quantity").querySelector("#quantity").stepUp();
  });
});

function redirect_to_other_lang(changeTo = "eng") {
  if (changeTo == "eng") {
    document.querySelector('.trp-language-wrap a[title="English"]')
      ? document.querySelector('.trp-language-wrap a[title="English"]').click()
      : null;
  }

  if (changeTo == "ru") {
    document.querySelector('.trp-language-wrap a[title="Russian"]')
      ? document.querySelector('.trp-language-wrap a[title="Russian"]').click()
      : null;
  }
}

// перевіряє чи мова сходиться з валютою
// currentLanguage.toLocaleLowerCase() == "en_us" &&
// currentCurrency.toLocaleLowerCase() != "usd"
//   ? (location.search += (location.search ? "&" : "?") + "_amc-currency=USD")
//   : null;

// currentLanguage.toLocaleLowerCase() == "ru_ru" &&
// currentCurrency.toLocaleLowerCase() != "kzt"
//   ? (location.search += (location.search ? "&" : "?") + "_amc-currency=KZT")
//   : null;

// Remove the hash from the URL after the page is fully loaded
// window.addEventListener("load", () => {
//   window.location.href.includes("_amc-currency")
//     ? window.history.replaceState({}, document.title, window.location.pathname)
//     : null;
// });

window.location.href.indexOf("/ru/") != -1
  ? $(".js_ru").addClass("current_lang")
  : $(".js_eng").addClass("current_lang");

$(".js_ru").click(function () {
  // localStorage.setItem("currentLanguageGlowcontent", "ru");
  $(this).hasClass("current_lang") ? null : redirect_to_other_lang("ru");
});

$(".js_eng").click(function () {
  // localStorage.setItem("currentLanguageGlowcontent", "eng");
  $(this).hasClass("current_lang") ? null : redirect_to_other_lang("eng");
});

// cookie popup

localStorage.getItem("cookieAgreeGlowcontent") != "true"
  ? document.querySelector(".cookie-policy").classList.remove("hide")
  : null;

document.querySelectorAll("#cookieAccept").forEach((el) => {
  el.addEventListener("click", () => {
    localStorage.setItem("cookieAgreeGlowcontent", true);
    document.querySelector(".cookie-policy")
      ? document.querySelector(".cookie-policy").classList.add("hide")
      : null;
  });
});

document.querySelectorAll("#cookie-close").forEach((el) => {
  el.addEventListener("click", () => {
    el.closest(".popup-modal").classList.add("hide");
  });
});

// end cookie popup

// header currency switcher logic

document.querySelectorAll(".currency-switcher").forEach((el) => {
  let currentCurrency = el.getAttribute("data-current");

  el.querySelectorAll("a").forEach((link) => {
    if (
      link.getAttribute("href").replace("?_amc-currency=", "") ==
      currentCurrency
    ) {
      link.classList.add("current-currency");

      let cloneLi = link.cloneNode(true);

      el.insertAdjacentElement("afterbegin", cloneLi);

      link.remove();
    } else {
      link.classList.remove("current-currency");
    }
  });
});

// end header currency switcher logic

// mini popup all fucntions

let toggleShopPopup = () =>
  document
    .querySelectorAll(".mini-shop-popup")
    .forEach((el) => el.classList.toggle("hide"));

let closeShopPopup = () =>
  document
    .querySelectorAll(".mini-shop-popup")
    .forEach((el) => el.classList.add("hide"));

// Обробник події зміни кількості товару

let changeCounter = 0;
document.querySelectorAll(".wc-menu-cart__quantity input").forEach((el) => {
  el.addEventListener("change", function (event) {
    changeCountEvent(event.target);
  });
});

// Функція для оновлення кількості товару через AJAX
function updateCartItemQuantity(cartItemKey, quantity) {
  let url = `${document.location.origin}/wp/wp-admin/admin-ajax.php`;
  let data = new URLSearchParams();
  data.append("action", "update_cart_item_quantity");
  data.append("cart_item_key", cartItemKey);
  data.append("quantity", quantity);

  // console.log(url);

  fetch(url, {
    method: "POST",
    body: data,
  })
    .then(function (response) {
      if (response.ok) {
        // Оновити міні-корзину після успішного оновлення кількості товару
        // updateCustomMiniCart();
        // console.log("suc ses", response);
        // updateCartCount();
        updateMiniCart();
      }
    })
    .catch(function (error) {
      console.error("Помилка AJAX-запиту:", error);
    });
}

let changeCountEvent = (el) => {
  changeCounter++;

  setTimeout(() => {
    // console.log("-------------------");
    // console.log(changeCounter);
    changeCounter--;
    // console.log(changeCounter);

    if (changeCounter < 1) {
      // console.log("work");
      let target = el;
      let item = target.closest(".wc-menu-cart__product");
      let quantity = parseInt(target.value);
      let cartItemKey = item.getAttribute("data-cart_item_key");

      // console.log(quantity, cartItemKey);

      if (quantity > 0 && cartItemKey) {
        updateCartItemQuantity(cartItemKey, quantity);
      }
    }
  }, 500);
};

let inputUp = (el) => {
  let input = el.closest("div").querySelector("input");
  input.stepUp();
  changeCountEvent(input);
};

let inputDown = (el) => {
  let input = el.closest("div").querySelector("input");
  input.stepDown();
  changeCountEvent(input);
};

let updateCartCount = () => {
  let productsCount = 0;

  document
    .querySelectorAll(".mini-shop-popup .wc-menu-cart__quantity input")
    .forEach((el) => (productsCount += parseInt(el.value)));

  document
    .querySelectorAll(".mini-cart-icon-count")
    .forEach((el) => (el.textContent = productsCount));
};

updateCartCount();

function updateMiniCart() {
  var url = `${document.location.origin}/wp/wp-admin/admin-ajax.php`;

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: "action=custom_update_mini_cart", // Дія для оновлення міні-корзини
  })
    .then(function (response) {
      return response.text();
    })
    .then(function (data) {
      // Оновлення контенту міні-корзини на сторінці
      var miniCartWrapper = document.querySelector(".wc-toggle-button-wrapper");
      miniCartWrapper.innerHTML = data;

      // console.log("ok");
      updateCartCount();
    })
    .catch(function (error) {
      console.error(error);
    });
}

// document.querySelector("body").addEventListener("added_to_cart", updateCartCount)

// function customFunction() {
//   // Ваша кастомна функція
//   console.log("Це моя кастомна функція");
// }

document.addEventListener("DOMContentLoaded", function () {
  $(document.body).on("added_to_cart", () => {
    updateCartCount();

    if (document.querySelector(".popup-added-to-card")) {
      document.querySelector(".popup-added-to-card").classList.remove("hide");

      setTimeout(
        () =>
          document.querySelector(".popup-added-to-card").classList.add("hide"),
        5000
      );
    }
  });
  $(document.body).on("wc_fragments_loaded", () => {
    updateCartCount();
  });
});

// end mini popup all fucntions

// popup added to card logic

document.querySelectorAll(".popup-added-to-card-close").forEach((el) => {
  el.addEventListener("click", () => {
    el.closest(".popup-added-to-card").classList.add("hide");
  });
});

// end popup added to card logic

// calculator buttons logic
// let buttonArray = [186, 187, 188];
let formEl = document.querySelector(".calculator_wrap .calculator_right-side");
        let buttonAddToCard = formEl.querySelector(".add_to_cart_button");
        let quantityInput = formEl.querySelector("#word-length");
        let radioButtons = formEl.querySelectorAll('input[name="exp"]');

        function trimTrailingZeros(value) {
          if (value.includes('.')) {
              value = value.replace(/0+$/, ''); // удаляем нули в конце
              value = value.replace(/\.$/, ''); // удаляем точку, если она стала последним символом
          }
          return value;
      }

        let updateForm = () => {
            let productId = buttonArray[formEl.querySelector('input[name="exp"]:checked').value];
            let productQuantity = Math.floor(quantityInput.value / 25);

            let intervalEl = document.createElement("div");
            intervalEl.innerHTML = buttonObject[productId];

            let priceSymbol = intervalEl.querySelector(".woocommerce-Price-currencySymbol").textContent;

            let rawPrice = parseFloat(intervalEl.querySelector("bdi").textContent.replace("$", "").replace("₸", ""));
let decimals = (buttonArray.includes(parseInt(productId))) ? 1 : 0; // используйте 3 знака после запятой для определенных продуктов и 2 для остальных
let productPriceMultiplication = rawPrice * productQuantity;
productPriceMultiplication = trimTrailingZeros(productPriceMultiplication.toFixed(decimals));


            formEl.querySelector(".price").innerHTML = intervalEl.innerHTML;
            formEl.querySelector(".price bdi").textContent = priceSymbol + productPriceMultiplication;

            buttonAddToCard.setAttribute("data-quantity", productQuantity);
            buttonAddToCard.setAttribute("data-product_id", productId);
        };

        let buttonStepUp = (el) => {
          let input = el.closest("div").querySelector("input[type=number]");
          let currentValue = parseInt(input.value) || 0;
          let remainder = currentValue % 25;
          if (remainder) {
              input.value = currentValue + (25 - remainder);
          } else {
              input.value = currentValue + 25;
          }
          updateForm();
      };

      let buttonStepDown = (el) => {
        let input = el.closest("div").querySelector("input[type=number]");
        let currentValue = parseInt(input.value) || 0;
        let remainder = currentValue % 25;
        if (currentValue <= 100) {
            input.value = 100;  // Если значение меньше или равно 100, устанавливаем его в 100
        } else {
            if (remainder) {
                input.value = currentValue - remainder;
            } else {
                input.value = Math.max(currentValue - 25, 100);  // Гарантируем, что значение не уходит ниже 100
            }
        }
        updateForm();
    };
    

        // Слушатели событий
        quantityInput.addEventListener("input", updateForm);

        radioButtons.forEach(radio => {
            radio.addEventListener("change", updateForm);
        });

        // Передаю функции для доступа в глобальной области видимости
        window.buttonStepUp = buttonStepUp;
        window.buttonStepDown = buttonStepDown;


        let changeValueBy = (el, amount) => {
          let input = formEl.querySelector("#word-length");
          let currentValue = parseInt(input.value) || 0;
          let newValue = currentValue + amount;
          input.value = Math.max(newValue, 100); // Гарантируем, что значение не опускается ниже 100
          updateForm();
      };

        updateForm();

// end calculator buttons logic
